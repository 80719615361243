import BRT from '../images/transport/brt.jpg'
import Canopy from '../images/transport/canopy-walk.jpg'
import Shuttle from '../images/transport/sunway-shuttle.jpg'

import Mice1 from '../images/mice/mice1.jpg'
import Mice2 from '../images/mice/mice2.jpg'

import Lagoon from '../images/leisure/sunway-lagoon.jpg'
import Pyramid from '../images/leisure/sunway-pyramid.jpg'
import Resort from '../images/leisure/sunway-resort-spa.jpg'

import Avenue from '../images/hospitality/geo-avenue.jpg'
import Pinnacle from '../images/hospitality/sunway-pinnacle.jpg'

import SunwayMC from '../images/healthcare/sunway-medical-center.jpg'
import SubangMC from '../images/healthcare/subang-medical-center.jpg'

import internationalSchool from '../images/education/international-school.jpg'
import Monash from '../images/education/monash.jpg'
import Sunway from '../images/education/sunway-university.jpg'
import Taylor from '../images/education/taylor-university.jpg'

export const TransportImg = [BRT, Canopy, Shuttle]
export const EducationImg = [Monash, internationalSchool, Sunway, Taylor]
export const MecicalCenterImg = [SunwayMC, SubangMC]
export const LeisureImg = [Lagoon, Pyramid, Resort]
export const MiceImg = [Mice2, Mice1]
export const HostelImg = [Avenue, Pinnacle]