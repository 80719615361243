import React, { Component } from 'react'
import '../style/component/imageViewer.scss'

export default class imageViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      translate: 0
    }
  }

  handleBack = (e) => {
    e.preventDefault();
    this.moveSlider(+1)
  }

  handleNext = (e) => {
    e.preventDefault();
    this.moveSlider(-1)
  }

  moveSlider = (position) => {
    const { translate } = this.state
    const imageSize = 100 / this.props.imagesSrc.length
    const movable = !(position === +1 && translate === 0) && !(position === -1 && translate.toFixed(2) === (- 100 + imageSize).toFixed(2))
    if (movable) {
      this.setState({
        translate: this.state.translate + imageSize * position,
      }, function(){
        console.log(this.state.translate)
      })
    }
    
  }



  render() {
    const {imagesSrc} = this.props
    const imageSize = `${100 / imagesSrc.length}%`
    const wrapperSize = `${100 * imagesSrc.length}%`
    return (
      <div className="images-viewer">
        <div className="images-viewer-wrapper" style={{ 
          'width': `${wrapperSize}`, 
          'transform': `translateX(${this.state.translate}%)`
          }}>
          {
            imagesSrc.map((image, index) => {
              return (
                <img src={image} alt={index} width={imageSize} key={index}/>
              )
            })
          }
        </div>
        <div className="action">
          <span onClick={this.handleBack}>&#8592;</span>
          <span onClick={this.handleNext}>&#8594;</span>
        </div>
      </div>
    )
  }
}
