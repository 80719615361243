import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/pageHero"
import ImageViewer from "../components/imageViewer"
import "../style/page/location.scss"

import {
  TransportImg,
  EducationImg,
  MecicalCenterImg,
  LeisureImg,
  MiceImg,
} from "../data/location"

const Place = props => {
  const position =
    (props.right && "right") ||
    (props.left && "left") ||
    (props.center && "center")
  return (
    <div className={`place-wrapper ${position}`}>
      <div className="place" style={{ width: `${props.width}%` }}>
        <div className="gallery">
          <ImageViewer imagesSrc={props.imageSet} />
        </div>
        <div className="detail">
          <p className="category">{props.category}</p>
          <div className="example">
            <ul className="example-list">
              {props.example.map((location, index) => {
                return (
                  <li key={index}>
                    {location.name}
                    {location.distance && (
                      <span className="distance">{location.distance}</span>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const LocationPage = () => (
  <Layout bgColor="#9eccd0">
    <SEO title="Location" />
    <Hero>
      <p>
        Perfect Location for <span>Work & Study</span>
        <br /> in the heart of Sunway City
      </p>
    </Hero>
    <section className="location">
      <Place
        right
        width={60}
        imageSet={EducationImg}
        category="Education"
        example={[
          { name: "Sunway University", distance: "< 1KM" },
          { name: "Monash University Sunway Campus", distance: "< 1KM" },
          { name: "Sunway International School", distance: "< 1KM" },
          { name: "Taylor University", distance: "< 1KM" },
        ]}
      />
      <p className="description">
        Our perfection location is only 10 mins walk from, e.g. Sunway
        University, Monash University, KDU university. and Not only that, it is
        also easily access all source of public transport. e.g. LRT, KTM, Sunway
        Shuter Bus, Sunway BRT.
      </p>
      <Place
        center
        width={100}
        imageSet={TransportImg}
        category="Transport"
        example={[
          { name: "Sunway BRT" },
          { name: "Canopy Walking" },
          { name: "Free Shuttle Bus" },
        ]}
      />
      <Place
        left
        width={60}
        imageSet={MecicalCenterImg}
        category="Health Care Center"
        example={[
          { name: "Sunway Medical Center", distance: "< 1KM" },
          { name: "Subang Medical Center", distance: "< 1KM" },
        ]}
      />
      <Place
        center
        width={60}
        imageSet={LeisureImg}
        category="Leisure"
        example={[
          { name: "Sunway Lagoon", distance: "1.5KM" },
          { name: "Sunway Pyramid", distance: "1.5KM" },
          { name: "Sunway Spa Resort", distance: "1.5KM" },
        ]}
      />
      <Place
        right
        width={50}
        imageSet={MiceImg}
        category="Mice"
        example={[{ name: "Sunway Convention Center", distance: "1.5KM" }]}
      />
    </section>
  </Layout>
)

export default LocationPage
